import SB from '../SchemaBuilder';
import { FilterKeys } from '../utils/TypeUtils';
import { payloadSchema } from '../common/Analytics';

// Needed for browser backend.
// TODO: Remove.
export const APIPath = {
    /** Called by gcinstant/replicantExtensions, only on the Facebook platform. */
    AFTER_CONTEXT_SWITCH: 'afterContextSwitch',
    OAUTH_GET_ACCESS_TOKEN: 'oauth/getAccessToken',
    REPLICATE: 'replicate',
    LOGIN_OR_CREATE: 'loginOrCreate',
    LOGIN_OR_CREATE_WEB_PLAYER: 'loginOrCreateWebPlayer',
    TELEGRAM_WEBHOOK: 'telegramWebhook',
    TOKEN: 'token',
    TOKEN_WEB_PLAYER: 'tokenWebPlayer',
    FETCH_STATES: 'fetchStates',
    ASYNC_GETTER: 'asyncGetter',
    FB_WEBHOOK: 'fbwebhook',
    UPLOAD_USER_ASSET: 'userAsset',
    READ_KEY_VALUES: 'kv/read',
    WRITE_KEY_VALUES: 'kv/write',
    READ_INTERNAL_KEY_VALUES: 'ikv/read',
    IOS_POST_APN: 'iosPostApn',
    ANDROID_POST_PUSH_NOTIFICATION: 'androidPushFcm',
    PUSH_NOTIFICATION: 'pushNotification',
    FB_MESSAGING_REFERRALS: 'fbmessaging_referrals',
    USER_DATA_DELETE: 'deletion',
    KOMOJU_WEBHOOK: 'komojuWebhook',
    XSOLLA_WEBHOOK: 'xsollaWebhook',
    LINE_WEBHOOK: 'line-webhook',
    INFER_GENDER_FROM_NAME: 'inferGenderFromName',
    OTP_INITIATE_ADD_RECEIVER: 'otpInitiateAddReceiver',
    OTP_INITIATE_LOGIN: 'otpInitiateLogin',
    SOCIAL_GRAPH_UPDATE_SELF: 'socialGraphUpdateSelf',
    SOCIAL_GRAPH_TRACK_INTERACTIONS: 'socialGraphTrackInteractions',
    STRIPE_WEBHOOK: 'stripe_webhook',
    ZOOM_WEBHOOK: 'zoomWebhook',
} as const;

const iosNotificationSchema = SB.object({
    alert: SB.union([
        SB.string(),
        SB.object({
            title: SB.string(),
            subtitle: SB.string().optional(),
            body: SB.string(),
            'launch-image': SB.string().optional(),
        }),
    ]),
    badge: SB.number().optional(),
    sound: SB.string().optional(),
});
const androidNotificationSchema = SB.object({
    title: SB.string(),
    icon: SB.string(),
    color: SB.string().optional(), // icon color
    body: SB.string().optional(), // notification text
    sound: SB.string().optional(),
});

//
// API declaration.

// The production API contains endpoints that are always available.
const prodApi = {
    _healthcheck: get(null),

    [APIPath.AFTER_CONTEXT_SWITCH]: post(
        SB.object({
            id: SB.string(),
            auth: SB.string(),
            contextSwitchEventId: SB.string(),
        }),
    ),

    [APIPath.OAUTH_GET_ACCESS_TOKEN]: post(
        SB.object({
            code: SB.string(),
            clientId: SB.string().optional(),
            redirectUri: SB.string().optional(),
        }),
    ),

    [APIPath.LOGIN_OR_CREATE]: post(
        SB.object({
            id: SB.string(),
            auth: SB.string().optional(),
            onLoginActionArgs: SB.unknown(),
            prefetchKeys: SB.array(SB.string()).optional(),
            prefetchInternalKeys: SB.array(SB.string()).optional(),
            sessionName: SB.string().optional(),
            sid: SB.string(),
            skipOnLoginAction: SB.boolean().optional(), // onLoginAction is skipped in case of client refresh
        }),
    ),

    [APIPath.LOGIN_OR_CREATE_WEB_PLAYER]: post(
        SB.object({
            clientAppName: SB.string().optional(),
            // `id` and `auth` are omitted in case of new anonymous web player login:
            id: SB.string().optional(),
            auth: SB.string().optional(),
            loginToken: SB.object({ token: SB.string(), userId: SB.string() }).optional(),
            onLoginActionArgs: SB.unknown(),
            otp: SB.object({
                code: SB.string(),
                verificationId: SB.string(),
            }).optional(),
            prefetchKeys: SB.array(SB.string()).optional(),
            prefetchInternalKeys: SB.array(SB.string()).optional(),
            sessionName: SB.string().optional(),
            sid: SB.string(),
            skipOnLoginAction: SB.boolean().optional(), // onLoginAction is skipped in case of client refresh
        }),
    ),

    [APIPath.REPLICATE]: post(
        SB.object({
            abTestsDynamicConfig: SB.map(
                SB.object({ active: SB.boolean(), rollOut: SB.number(), stopAssignment: SB.boolean().optional() }),
            ),
            id: SB.string(),
            auth: SB.string().optional(),
            queue: SB.array(
                SB.object({
                    fn: SB.string(),
                    args: SB.unknown().optional(),
                    async: SB.boolean(),
                    meta: SB.object({ now: SB.number() }).optional(),
                }).customValidator((value) => {
                    if (value.async && !value.meta) {
                        return 'Client time must be provided for async actions.';
                    }

                    return null;
                }),
            ),
            rev: SB.number(),
            clientRandomSeed: SB.number(),
            requestedProfileIds: SB.array(SB.string()).optional(),
            consistentFetchIds: SB.array(SB.string()),
            sessionName: SB.string().optional(),
            sid: SB.string(),
            crqid: SB.string(),
        }),
    ),

    [APIPath.FETCH_STATES]: post(
        SB.object({
            id: SB.string(),
            auth: SB.string().optional(),
            ids: SB.array(SB.string()),
            consistentFetchIds: SB.array(SB.string()),
            friendRevs: SB.map(SB.number()),
        }),
    ),

    [APIPath.ASYNC_GETTER]: post(
        SB.object({
            id: SB.string(),
            sid: SB.string(),
            auth: SB.string().optional(),
            name: SB.string(),
            args: SB.unknown(),
            consistentFetchIds: SB.array(SB.string()),
        }),
    ),

    [APIPath.FB_WEBHOOK]: endpoint({
        get: null,
        post: SB.map(SB.unknown()),
    }),

    [APIPath.TELEGRAM_WEBHOOK]: post(SB.unknown()),

    [APIPath.USER_DATA_DELETE]: endpoint({
        get: SB.object({ id: SB.string(), fbclid: SB.string().optional() }),
        post: null,
    }),

    [APIPath.UPLOAD_USER_ASSET]: post(
        SB.object({
            id: SB.string(),
            auth: SB.string().optional(),
            dataUrl: SB.string(),
        }),
    ),

    [APIPath.READ_KEY_VALUES]: post(
        SB.object({ id: SB.string(), auth: SB.string().optional(), keys: SB.array(SB.string()) }),
    ),

    [APIPath.WRITE_KEY_VALUES]: post(
        SB.object({
            id: SB.string(),
            auth: SB.string().optional(),
            pairs: SB.map(SB.string()),
            opts: SB.object({ expiresInMs: SB.int().optional() }).optional(),
        }),
    ),

    [APIPath.READ_INTERNAL_KEY_VALUES]: post(
        SB.object({ id: SB.string(), auth: SB.string().optional(), keys: SB.array(SB.string()) }),
    ),

    admin: post(
        SB.object({
            auth: SB.string(),
            action: SB.string(),
            args: SB.unknown(),
        }),
    ),

    'ios-bridge': endpoint({
        get: SB.object({ auth: SB.string(), id: SB.string(), uuid: SB.string() }),
        post: SB.object({ auth: SB.string(), id: SB.string(), uuid: SB.string(), payload: SB.string() }),
    }),

    [APIPath.IOS_POST_APN]: post(
        SB.object({
            id: SB.string(),
            auth: SB.string().optional(),
            receiverId: SB.string(),
            notification: iosNotificationSchema,
            payload: payloadSchema,
            imageUrl: SB.string().optional(),
            analyticsUserProps: SB.map(SB.unknown()).optional(),
        }),
    ),

    [APIPath.ANDROID_POST_PUSH_NOTIFICATION]: post(
        SB.object({
            id: SB.string(),
            auth: SB.string().optional(),
            receiverId: SB.string(),
            notification: androidNotificationSchema,
            payload: payloadSchema,
            imageUrl: SB.string().optional(),
            analyticsUserProps: SB.map(SB.unknown()).optional(),
        }),
    ),

    [APIPath.PUSH_NOTIFICATION]: post(
        SB.object({
            id: SB.string(),
            auth: SB.string().optional(),
            receiverId: SB.string(),
            notifications: SB.object({
                ios: iosNotificationSchema,
                android: androidNotificationSchema,
            }),
            payload: payloadSchema,
            imageUrl: SB.string().optional(),
            analyticsUserProps: SB.map(SB.unknown()).optional(),
        }),
    ),

    [APIPath.KOMOJU_WEBHOOK]: post(SB.unknown()),

    [APIPath.XSOLLA_WEBHOOK]: post(SB.unknown()),

    [APIPath.LINE_WEBHOOK]: post(
        SB.object({
            destination: SB.string(),
            events: SB.array(SB.unknown()),
        }),
    ),

    [APIPath.TOKEN]: post(SB.object({ id: SB.string(), auth: SB.string().optional() })),

    [APIPath.TOKEN_WEB_PLAYER]: post(SB.object({ id: SB.string(), auth: SB.string() })),

    [APIPath.INFER_GENDER_FROM_NAME]: post(
        SB.object({
            id: SB.string(),
            auth: SB.string(),
            name: SB.string(),
        }),
    ),

    [APIPath.OTP_INITIATE_ADD_RECEIVER]: post(
        SB.object({
            id: SB.string(),
            auth: SB.string(),
            consentText: SB.string().customValidator((val) =>
                val.length === 0 ? 'Value must be a non-empty string' : null,
            ),
            receiver: SB.string(),
            templateId: SB.string().optional(),
            type: SB.tuple<'sms'>(['sms']),
        }),
    ),

    [APIPath.OTP_INITIATE_LOGIN]: post(
        SB.object({
            clientAppName: SB.string().optional(),
            additionalTemplateData: SB.map(SB.string()).optional(),
            receiver: SB.string(),
            templateId: SB.string().optional(),
            type: SB.tuple<'sms'>(['sms']),
        }),
    ),

    [APIPath.SOCIAL_GRAPH_UPDATE_SELF]: post(
        SB.object({
            id: SB.string(),
            auth: SB.string(),
            nonPlayerFriendIds: SB.array(SB.string()),
            meta: SB.map(SB.unknown()),
        }),
    ),
    [APIPath.STRIPE_WEBHOOK]: post(SB.unknown()),

    [APIPath.SOCIAL_GRAPH_TRACK_INTERACTIONS]: post(
        SB.object({
            id: SB.string(),
            auth: SB.string(),
            interactions: SB.array(
                SB.object({
                    receiverId: SB.string(),
                    timestamp: SB.int(),
                    type: SB.string(),
                    meta: SB.map(SB.unknown()),
                }),
            ),
        }),
    ),

    [APIPath.ZOOM_WEBHOOK]: post(SB.map(SB.unknown())),
};

// The development API contains endpoints that are not available in production.
const devApi = {
    nukeUser: get(SB.object({ id: SB.string(), secret: SB.tuple(['iamsure']) })),
    nukeDB: get(SB.object({ secret: SB.tuple(['iamsupersure']) })),
    testUsers: post(SB.object({ id: SB.string(), auth: SB.string().optional(), states: SB.map(SB.unknown()) })),
};

export const api = {
    ...devApi,
    ...prodApi,
};

//
// API types.

type API = {
    [U in keyof typeof api]: {
        [M in keyof (typeof api)[U]]: (typeof api)[U][M] extends { schema: infer S }
            ? S extends null
                ? null
                : SB.ExtractType<S>
            : never;
    };
};

export type GetAPI = { [K in FilterKeys<API, { get: any }>]: API[K] extends { get: infer T } ? T : never };
export type PostAPI = { [K in FilterKeys<API, { post: any }>]: API[K] extends { post: infer T } ? T : never };

//
// Helpers.

type GetSchema = SB.ObjectSchema<any> | null;
type PostSchema = SB.Schema | null;

function get<TSchema extends GetSchema>(schema: TSchema) {
    return { get: { schema } };
}

function post<TSchema extends PostSchema>(schema: TSchema) {
    return { post: { schema } };
}

function endpoint<TGetSchema extends GetSchema, TPostSchema extends PostSchema>(schema: {
    get: TGetSchema;
    post: TPostSchema;
}) {
    return { ...get(schema.get), ...post(schema.post) };
}
